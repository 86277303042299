<script setup>
import { themeConfig } from "@themeConfig";
import { VNodeRenderer } from "@layouts/components/VNodeRenderer";
import { useDisplay } from "vuetify";

const { smAndUp } = useDisplay();
const config = useRuntimeConfig();
const appName = config.public.appName;
const { t } = useI18n();

const navigateTo = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};

const trade = computed(() => {
  return [
    {
      title: t("footer.zfinances"),
      to: null,
      hide: false,
    },
    {
      title: t("footer.bitmart"),
      to: config.public.bitmartLink,
      hide: false,
    },
    {
      title: t("footer.lbank"),
      to: config.public.lbankLink,
      hide: false,
    },
    {
      title: t("footer.mexc"),
      to: config.public.mexcLink,
      hide: false,
    },
    {
      title: t("footer.p2b"),
      to: config.public.twobLink,
      hide: false,
    },
  ];
});

const resources = computed(() => {
  return [
    {
      title: t("footer.documentation"),
      to: config.public.documentationLink,
      hide: false,
    },
    {
      title: t("footer.github"),
      to: config.public.githubLink,
      hide: false,
    },
    {
      title: t("footer.blog"),
      to: config.public.blogLink,
      hide: false,
    },
  ];
});

const useZChains = computed(() => {
  return [
    {
      title: t("footer.block_explorer"),
      to: "/",
      hide: false,
    },
    {
      title: t("footer.ecosystem"),
      to: "/ecosystem",
      hide: false,
    },
    {
      title: t("footer.bridge"),
      to: "/",
      hide: false,
    },
    {
      title: t("footer.swap"),
      to: "/",
      hide: false,
    },
  ];
});

const social = computed(() => {
  return [
    {
      icon: "custom-twitter",
      title: t("footer.twitter"),
      to: config.public.socialLinksTwitter,
    },
    {
      icon: "custom-telegram",
      title: t("footer.telegram"),
      to: config.public.socialLinksTelegram,
    },
    {
      icon: "custom-discord",
      title: t("footer.discord"),
      to: config.public.socialLinksDiscord,
    },
    {
      icon: "custom-instagram",
      title: t("footer.instagram"),
      to: config.public.socialLinksInstagram,
    },
    {
      icon: "custom-youtube",
      title: t("footer.youtube"),
      to: config.public.socialLinksYoutube,
    },
  ];
});
</script>

<template>
  <div class="w-100 h-100 border-t border-opacity-25">
    <div class="h-100">
      <VRow no-gutters>
        <VCol
          cols="12"
          sm="3"
          class="d-flex flex-column align-start py-10 pt-sm-15 pb-sm-10 px-4 px-sm-6 px-md-12 order-last order-sm-first"
        >
          <div
            class="d-flex flex-column mb-auto align-start w-100 ga-sm-0 mt-sm-0 w-100 mx-2 mx-sm-0"
          >
            <div class="w-100 mb-8">
              <VNodeRenderer :nodes="themeConfig.app.logo" class="logo-image"/>
            </div>
          </div>

          <div class="mt-8 mx-2 mx-sm-0">
            <span
              class="d-flex align-center text-grey-3"
            >
              &copy;
              {{ new Date().getFullYear() }}
              {{ appName }}. All rights reserved.
            </span>
          </div>
        </VCol>
        <VCol
          cols="12"
          sm="9"
          class="py-15 px-5 border-opacity-25 ps-md-10"
          :class="!smAndUp ? 'border-b' : 'border-s'"
        >
          <VRow class="mb-sm-16">
            <VCol cols="12" sm="3">
              <div class="d-flex flex-sm-column flex-row">
                <div class="title-text mb-sm-10"><span class="text-uppercase text-grey-3">{{ $t("footer.trade") }}</span></div>
                <div class="mt-sm-16 column-text">
                  <div class="d-flex flex-column">
                    <div v-for="item in trade" :key="item" class="footer-list-item">
                      <NuxtLink :to="item.to" class="footer-list-link" target="_blank" rel="noopener noreferrer">
                        <span class="text-uppercase">{{ item.title }}</span>
                      </NuxtLink>
                    </div>
                  </div>
                </div>
              </div>
            </VCol>
            <VCol cols="12" sm="3">
              <div class="d-flex flex-sm-column flex-row">
                <div class="title-text mb-sm-10">
                  <span class="text-uppercase text-grey-3">{{ $t("footer.resources") }}</span>
                </div>
                <div class="mt-sm-16 column-text">
                  <div class="d-flex flex-column">
                    <div v-for="item in resources" :key="item" class="footer-list-item">
                      <NuxtLink :to="item.to" class="footer-list-link" target="_blank" rel="noopener noreferrer">
                        <span class="text-uppercase">{{ item.title }}</span>
                      </NuxtLink>
                    </div>
                  </div>
                </div>
              </div>
            </VCol>
            <VCol cols="12" sm="3">
              <div class="d-flex flex-sm-column flex-row">
                <div class="title-text mb-sm-10">
                  <span class="text-uppercase text-grey-3">{{ $t("footer.use_zchains") }}</span>
                </div>
                <div class="mt-sm-16 column-text">
                  <div class="d-flex flex-column">
                    <div v-for="item in useZChains" :key="item" class="footer-list-item">
                      <NuxtLink :to="item.to" class="footer-list-link">
                        <span class="text-uppercase">{{ item.title }}</span>
                      </NuxtLink>
                    </div>
                  </div>
                </div>
              </div>
            </VCol>
            <VCol cols="12" sm="3">
              <div class="d-flex flex-sm-column flex-row">
                <div class="title-text mb-sm-10">
                  <span class="text-uppercase text-grey-3">{{ $t("footer.social") }}</span>
                </div>
                <div class="mt-sm-16 column-text">
                  <div v-for="item in social" class="footer-list-item">
                    <div
                      @click="navigateTo(item.to)"
                      class="footer-list-link d-flex align-center gap-3"
                    >
                      <VIcon size="14" :icon="item.icon" />
                      <a role="button">
                        <span class="footer-text text-uppercase">{{
                          item.title
                        }}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </VCol>
          </VRow>
        </VCol>
      </VRow>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@configured-variables" as variables;

.footer-list-item {
  cursor: pointer;
  position: relative;
  font-family: "Geomanist";
  &::before {
    content: "";
    position: absolute;
    top: 15%;
    left: 0;
    width: 3px;
    height: 0;
    background: rgb(var(--v-theme-primary));
    border-radius: 20rem;
    transition: height 0.3s, transform 0.3s;
    margin-top: auto;
    margin-bottom: auto;
  }

  &:hover {
    .footer-list-link {
      color: rgb(var(--v-theme-primary));
      transform: translateX(0.5rem);
    }

    &::before {
      height: 70%;
    }
  }

  .footer-list-link {
    display: block;
    position: relative;
    font-family: var(--font);
    font-size: 0.9rem;
    transition: color 0.3s, transform 0.3s;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}

.logo-image {
  display: block;
  transform-origin: top left;
  scale: 2;
}
@media(max-width: 599px) {
  .title-text {
    width: 40%;
  }

  .column-text {
    width: 60%;
  }
}
</style>
